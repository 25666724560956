import React, { useState } from "react";

const About = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleNetworkList = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const networkListData = [
    {
      year: 2001,
      title: "Unicampus Education Network",
      logo: "../assets/images/unicampus_logo.svg",
      link: "https://www.myunicampus.com/site/index.php",
      content:
      "Unicampus Global is a leading education consultancy in Nepal, specializing in guiding students through the process of pursuing higher education abroad. With a team of experienced counselors and advisors, Unicampus Global provides personalized assistance to students seeking to study in countries such as Australia, the UK, the USA, Canada, New Zealand, and more. From university selection and application assistance to visa counseling and pre-departure orientation, Unicampus Global offers end-to-end support to ensure a smooth transition for students planning to study overseas. The consultancy has established partnerships with reputable universities and educational institutions worldwide, enabling students to access a wide range of programs and opportunities. Unicampus Global also organizes educational events, seminars, and workshops to provide students with valuable insights into studying abroad and helps them make informed decisions about their academic and career paths. With a commitment to excellence and integrity, Unicampus Global has earned the trust and respect of students and parents alike, making it a preferred choice for education consulting services in Nepal."

    },
    {
      year: 2010,
      title: "Softwarica College of IT and E-commerce",
      logo: "../assets/images/softwarica_logo.svg",
      link: "https://www.softwarica.edu.np/",
      content:
      "Softwarica College of IT & E-Commerce is a premier educational institution in Nepal, dedicated to providing high-quality education in the fields of information technology and e-commerce. Established with the vision of bridging the gap between theoretical knowledge and practical skills, Softwarica College offers a range of undergraduate and graduate programs designed to meet the demands of the rapidly evolving IT industry. With state-of-the-art facilities, experienced faculty members, and industry partnerships, Softwarica College ensures that students receive comprehensive training and hands-on experience in areas such as programming, web development, cybersecurity, digital marketing, and more. The college places a strong emphasis on experiential learning, encouraging students to participate in internships, projects, and industry collaborations to gain real-world experience and enhance their employability. Softwarica College is committed to fostering innovation, creativity, and entrepreneurship among its students, empowering them to become future leaders and contributors to the IT and e-commerce sectors."
      },
    {
      year: 2010,
      title: "Sunway International Business School",
      logo: "../assets/images/sunway_logo.svg",
      link: "https://sunway.edu.np/",
      content:
      "Formerly known as Sunway International Business School, Sunway College has transformed its focus to meet the evolving demands of the digital age. With a forward-looking approach, Sunway College is committed to shaping the next generation of AI leaders and digital innovators. At Sunway College, we offer cutting-edge courses in Artificial Intelligence (AI), Data Science, Digital Marketing, and other emerging technologies. Our curriculum is designed to equip students with the knowledge, skills, and expertise needed to thrive in the rapidly changing digital landscape. Through hands-on learning experiences, industry collaborations, and real-world projects, students at Sunway College gain practical insights and valuable experience in AI, data analysis, machine learning, and digital marketing strategies. Our faculty members are industry experts and experienced educators who are dedicated to providing students with a supportive and inspiring learning environment. With state-of-the-art facilities and resources, including dedicated AI labs and digital classrooms, Sunway College offers an immersive learning experience that prepares students for success in the digital economy. In addition to academic excellence, Sunway College emphasizes the development of critical thinking, problem-solving, and entrepreneurial skills. We encourage students to think creatively, challenge the status quo, and explore innovative solutions to real-world challenges. Through our commitment to excellence, innovation, and student success, Sunway College is empowering the next generation of AI leaders and digital pioneers to make a positive impact in the world. Let me know if you need further adjustments or if there are any additional details you'd like to include!"

    },
    {
      year: 2019,
      title: "Digi School Global",
      logo: "../assets/images/digischool_logo.svg",
      link: "https://digischoolglobal.com/",
      content:
      "Digischool Global is at the forefront of transforming education through digital innovation and technology integration. As a pioneer in edtech solutions, Digischool Global aims to revolutionize teaching and learning practices by harnessing the power of digital tools, resources, and platforms. The company offers a comprehensive suite of products and services designed to enhance the educational experience for students, teachers, and institutions. From interactive digital textbooks and e-learning platforms to virtual classrooms and educational apps, Digischool Global provides cutting-edge solutions that facilitate personalized learning, collaboration, and engagement. By leveraging technologies such as artificial intelligence, augmented reality, and gamification, Digischool Global creates dynamic and interactive learning environments that cater to diverse learning styles and preferences. The company also offers professional development programs and training workshops for educators to help them leverage technology effectively in their teaching practices. With a commitment to innovation, quality, and accessibility, Digischool Global is shaping the future of education and empowering learners of all ages to thrive in the digital age."

    },
    // {
    //   year: 2018,
    //   title: "The Culture Resort",
    //   logo: "../assets/images/cultureresort_logo.svg",
    //   content:
    //     "The Culture Resort, a part of the SoftEd Group, offers a unique blend of traditional Nepali elements and modern luxury alongside the shores of Fewa Lake. With stunning views, contemporary amenities, a garden space, diverse cuisine, and a vibrant open bar, The Culture Resort promises an unforgettable stay immersed in local flavor.",
    // },

    {
      year: 2021,
      title: "Digi Medical",
      logo: "../assets/images/digimedical_logo.svg",
      link: "https://app.digimedicalsewa.com/",
      // linkName: "Visit Site"
      content:
      "   Digimedical Sewa is a healthcare technology company dedicated to improving access to medical services and resources through digital solutions. The company offers a range of products and services aimed at enhancing healthcare delivery, patient care, and medical education. From telemedicine platforms and electronic health records systems to medical imaging solutions and remote monitoring devices, Digimedical Sewa leverages technology to bridge the gap between patients and healthcare providers. The company also provides consultancy services and training programs to healthcare institutions and professionals to help them adopt and utilize digital tools effectively. With a focus on innovation, reliability, and affordability, Digimedical Sewa is committed to transforming the healthcare landscape in Nepal and beyond."

    },
    {
      year: 2021,
      title: "Digi Technology",
      logo: "../assets/images/digitechnology_logo.svg",
      link: "https://digitechnologynepal.com/",
      content:
        "   Digi Technology Nepal is a technology solutions provider specializing in IT services, digital marketing, and software development. The company offers a wide range of services to businesses across various industries, including web development, mobile app development, search engine optimization, social media marketing, and more. Digi Technology Nepal helps businesses leverage technology to improve efficiency, productivity, and customer engagement. With a team of experienced professionals and a customer-centric approach, the company delivers customized solutions tailored to meet the unique needs and objectives of each client. Whether it's building a website, launching a digital marketing campaign, or developing a custom software application, Digi Technology Nepal is committed to delivering high-quality solutions that drive results and create value for its clients."
    },
    {
      year: 2021,
      title: "UK COLLEGES",
      logo: "../assets/images/ukcolleges_logo.svg",
      link: "https://digitechnologynepal.com/",
      content:
      "The UK Colleges is a leading provider of educational pathways for Nepali students seeking to pursue higher education in the United Kingdom. Through partnerships with reputable UK universities and colleges, The UK Colleges offers a range of academic programs and courses designed to prepare students for successful academic and professional careers abroad. The company provides comprehensive support services to students, including university placement assistance, visa guidance, accommodation arrangements, and pre-departure orientation programs. With a commitment to quality education and student welfare, The UK Colleges aims to empower Nepali students to fulfill their aspirations of studying abroad and gaining valuable international experience."

    },
    
    {
      year: 2023,
      title: "Digiworld",
      logo: "../assets/images/digiworld_logo.svg",
      link: "https://digiworld.ltd/",
      content:
        "Digiworld.ltd, Nepal's upcoming premier super app, where convenience meets innovation. . Founded by Mr. Bigyan Shrestha, former Co-Founder of Gyapu.com, Digiworld.ltd is poised to redefine the online shopping experience for Nepalese consumers. Digiworld.ltd, our vision is simple yet profound: to empower Nepal's digital marketplace by offering a comprehensive range of services all in one convenient platform. From ecommerce to edtech, healthtech to OTA (Online Travel Agency), we aim to cater to the diverse needs of our customers, making their digital lives easier and more hassle-free than ever before. Unlike traditional apps that focus on one specific niche, Digiworld.ltd is breaking barriers by offering a wide array of services under one roof. Whether you're a tech-savvy shopper, a parent looking for educational resources for your children, a health-conscious individual seeking wellness solutions, or a traveler in need of seamless booking experiences, Digiworld.ltd has got you covered.",
    },
    // {
    //   year: 2021,
    //   title: "Paramarsha Property",
    //   logo: "../assets/images/paramarsha_logo.svg",
    //   content:
    //     "Paramarsha Property, a part of the SoftEd Group, is Nepal's leading real estate platform focused on simplifying the home buying and selling process. Their innovative online platform empowers users to effortlessly search for and connect with properties that meet their needs.",
    // },
    // {
    //   year: 2022,
    //   title: "Mini Australia",
    //   logo: "../assets/images/miniaustralia_logo.svg",
    //   content:
    //     "Mini Australia, a part of the SoftEd Group, brings the vibrant spirit of Australia to Nepal. Discover the allure of Aussie life, explore educational opportunities, and experience a unique cultural connection at Mini Australia.",
    // },
  ];

  const qualityList = [
    {
      title: "Quality education",
      content:
        "We believe in power of education that helps to transform the lives of people through high-quality education. We believe through our education our students succeed, countries prosper, and societies benefit",
    },
    {
      title: "24/7 student services",
      content:
        "We offer resources for students that may be required in daily basis. Services like peer mentoring programs, academic support, job and housing services, clubs and societies, child care, therapy and medical services is open 24/7 in each of our institution.",
    },
    {
      title: "Study mode",
      content:
        "You will notice that different study modes are provided to students by each of our institution. This can substantially narrow down the shortlist of suitable institutions if you need a part-time , full time, on campus or distance education alternative.    ",
    },
  ];
  return (
    <>
      <section>
        <div className="element4">
          <img src="../assets/images/element4.png" alt="" />
        </div>
        <div className="about-top">
          <div className="about-icon">
            <a href="/">
              <i class="fa-solid fa-house"></i>
            </a>
            <i class="fa-solid fa-angle-right"></i>
            <p> About Us</p>
          </div>
          <div className="about-top-text">
            <h2>About Us</h2>
            <p>
            Softed Group pioneers the fusion of education and innovative technology, revolutionizing learning experiences for over 22 years. With a steadfast focus on integrating cutting-edge digital solutions into educational frameworks, we are committed to enhancing accessibility, engagement, and outcomes for learners of all ages.
            <br /><br />
Our journey began over two decades ago, driven by a vision to harness the power of technology to transform education. Since then, we have been at the forefront of innovation, continuously striving to redefine the way people learn and teach. At Softed Group, we embrace a holistic approach to education, leveraging technology to create interactive and immersive learning environments. From interactive learning tools to virtual classrooms, we provide educators and learners with the tools and resources they need to succeed in today's digital world. Our legacy of 22 years is a testament to our dedication to excellence and our unwavering commitment to pushing the boundaries of what's possible in education. By fostering the synergy between education and technology, we empower individuals and institutions to embrace the endless possibilities of tomorrow's digital landscape.

            </p>
          </div>
          <div className="about-top-last">
            <div className="about-top-elem">
              <img src="../assets/icons/piechart.svg" alt="piechart" />
              <p>Observe</p>
            </div>
            <div className="about-top-elem">
              <img src="../assets/icons/announcement.svg" alt="announcement" />
              <p>Innovate</p>
            </div>
            <div className="about-top-elem elem3">
              <img src="../assets/icons/desktop.svg" alt="desktop" />
              <p>Deliver</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-company">
        <div className="about-container">
          <div className="container-num">
            <p>01</p>
            <img src="../assets/icons/vision.svg" alt="" />
          </div>
          <h2>
            Our <br />
            Vision
          </h2>
          <p>
            SoftED vision is to provide leadership in the country's overall
            growth through a network of businesses that seek to elevate our
            clients, partners, and employees to a level that allows us to build
            the most successful and merry multi-cultural business community in
            which everyone is a proud member of the Softed family.
          </p>
        </div>
        <div className="about-container about-container-mid">
          <div className="container-num">
            <p>02</p>
            <img src="../assets/icons/mission.svg" alt="" />
          </div>
          <h2>
            Our <br />
            Mission
          </h2>
          <p>
            In a globally competitive market, we strive to constantly provide
            the most efficient and high-quality product and services while also
            giving comprehensive value and establishing an environmentally
            responsible workplace for our employees and customers.
          </p>
        </div>
        <div className="about-container">
          <div className="container-num">
            <p>03</p>
            <img src="../assets/icons/choose_us.svg" alt="" />
          </div>
          <h2>
            Why <br />
            Choose Us?
          </h2>
          <p id="check">
            We believe in quality and provide our society with a quality
            services which brings change in the lives of people through us.
          </p>
        </div>
      </section>
      <section>
        <div className="element5">
          <img src="../assets/images/element5.png" alt="" />
        </div>
        <div className="about-work">
          <div className="about-work-container">
            <div className="about-work-img">
              <img src="../assets/images/about_work1.svg" alt="" />
            </div>
            <div className="about-work-text">
              <h2>SoftED for Everyone</h2>
              <p>
                Softed is a group of companies delivering services, emphasizing
                technology in particular. We started with the major goal of
                educating people about technology and ended up educating them
                through technology; we have come a long way since we started.
                Shaped by a diverse group of entrepreneurial people working
                together to create a diverse business, we have pushed our
                boundaries by not just limiting ourselves to education and
                technology, but also expanding through a sub domain “Softed
                Business” which is also entering into various business domains
                such as tourism, healthcare and ecommerce. Working towards the
                overall development of society with a wide range of services
                available for each age group, we have taken an initiation called
                “Softed for everyone”, where everyone will profit or thrive as a
                result of its services and job possibilities.
              </p>
            </div>
          </div>
          <div className="about-work-container work-container2">
            <div className="about-work-text">
              <h2>Contributing more bricks to the nation’s development</h2>
              <p>
                Softed Group currently employs over 500 people who work for a
                variety of firms and have offered invaluable services as well as
                business and job prospects for our employees. Our organization
                will continue to expand in the future, contributing more bricks
                to the nation’s development. We believe that each organization
                has the potential to make a significant difference in the world.
                As a result, we are dedicated to the betterment of the country
                by providing numerous chances for the country’s youth and
                country’s growth.
              </p>
            </div>
            <div className="about-work-img">
              <img src="../assets/images/about_work2.svg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-services">
        <div className="services-text">
          <p>
            We impart quality service that could reach to all consumer with
            varied economic backgrounds.
          </p>
        </div>
        <div className="services-container">
          <div className="services-box">
            <div className="services-box-icon">
              <img src="../assets/icons/box_icon.svg" alt="" />
            </div>
            <div className="services-box-text">
              <h2>Life Changing Quality services</h2>
              <p>
                Through our extra ordinary and excellent service, we bring the
                possibilities of the digital age to the real world, bringing
                changes to people's lives.
              </p>
            </div>
          </div>
          <div className="services-box">
            <div className="services-box-icon">
              <img src="../assets/icons/box_icon.svg" alt="" />
            </div>
            <div className="services-box-text">
              <h2>Help You Choose Career Path</h2>
              <p>
                We have a various course and institution option available for
                you to choose from where we can lead your career in right
                direction.
              </p>
            </div>
          </div>
          <div className="services-box">
            <div className="services-box-icon">
              <img src="../assets/icons/box_icon.svg" alt="" />
            </div>
            <div className="services-box-text">
              <h2>
                Fulfilling Gap Between Quality Services and Client Satisfaction
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="element6">
          <img src="../assets/images/element6.png" alt="" />
        </div>
        <div className="about-network" id="ourCompanies">
          <div className="about-network-heading">
            <h2>
              OUR <br />
              <span>NETWORK</span>
            </h2>
            <p>
              Every one of our institutions has its own extraordinary image and
              programs for students who flourish to have global introduction. We
              persistently improve to offer the highest quality education and
              administrations with the polished skill that has made us pioneers
              in the field. As our network continues to evolve our precepts
              remain the same: our trust in the ability of education to
              transform lives which led us to founder to formalize and
              consolidate all current institutions under one umbrella called
              Softed.
            </p>
          </div>
          <div className="about-network-img">
            <img src="../assets/images/about_network.jpg" alt="" />
          </div>

          {networkListData.map((network, index) => (
            <div className="about-network-list" key={index}>
              <div
                className="network-list-title"
                onClick={() => toggleNetworkList(index)}
              >
                <div className="network-list-title-h3">
                  <span>{network.year}</span>
                  <h3>{network.title}</h3>
                </div>
                <div className="network-arrow">
                  <i
                    className={`fa-solid ${
                      activeIndex === index ? "fa-arrow-up" : "fa-arrow-down"
                    }`}
                  ></i>
                </div>
              </div>

              {activeIndex === index && (
                <div className="network-list-content-mid">
                  <div className="network-list-content">
                    <div className="network-logo-link">
                      <a
                        href={network.link}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textDecoration: "none",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={network.logo} alt="" />
                        <p
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            marginTop: "15px",
                          }}
                        >
                          Visit Site
                        </p>
                      </a>
                    </div>
                    <p>{network.content}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      <section className="about-quality">
        <h2>
          We believe in quality and provide our society with a quality services
          which brings change in the lives of people through us.
        </h2>
        <div className="quality-section">
          <div className="quality-img">
            <img src="../assets/images/Bitmap.svg" alt="" />
          </div>

          <div className="quality-content">
            {qualityList.map((quality, index) => (
              <div className="quality-content-list" key={index}>
                <h3>{quality.title}</h3>
                <p>{quality.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
